import React from 'react'
import { Col, Row } from 'antd'
import { Source, UnitsEnum, Indicator } from '../config/indicators-model'

export type ChartMetaSource = { global: Source[]; specific: { sources: Source[]; name: string; derivation: boolean }[] }

type Props = {
  units: UnitsEnum[]
  sources: ChartMetaSource
  description: string
  timeRange: number[]
  periodicity: string
  chart: Indicator
}

export const ChartMeta: React.FC<Props> = ({ sources, description, timeRange, units, periodicity, chart }) => {
  return (
    <>
      <h2>{chart.longTitle}</h2>
      <h3>Serie Temporal</h3>
      <Row>
        <Col span={24}>
          {timeRange[0]}-{timeRange[1]}
        </Col>
      </Row>
      <h3>Periodicidad</h3>
      <Row>
        <Col span={24}>{periodicity}</Col>
      </Row>
      <h3>Unidades</h3>
      <Row>
        <Col span={24}>{units.join(', ')}</Col>
      </Row>
      {sources.global.length ? (
        <>
          <h3>Fuentes principales:</h3>
          {sources.global.map(s => (
            <Row>
              <Col span={24}>
                <a href={s.link} target="_blank" rel="noopener noreferrer">
                  {s.name}
                </a>
              </Col>
            </Row>
          ))}
        </>
      ) : (
        ''
      )}
      {sources.specific.length ? (
        <>
          <h3>Fuentes detalladas:</h3>
          {sources.specific.map(s => (
            <Row className="chart-source">
              <Col span={14}>{s.name}</Col>
              <Col span={10}>
                {s.derivation ? 'Cálculo propio a partir de: ' : ''}
                {s.sources.map((ss, index) => (
                  <>
                    {index > 0 ? ', ' : ''}
                    <a href={ss.link} target="_blank" rel="noopener noreferrer">
                      {ss.name}
                    </a>
                  </>
                ))}
              </Col>
            </Row>
          ))}
        </>
      ) : (
        ''
      )}
      <h3>Descripción:</h3>
      <Row style={{ marginTop: 12 }}>
        <Col span={24}>{description}</Col>
      </Row>
    </>
  )
}
