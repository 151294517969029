import React from 'react'
import { Col, Layout, Row, Switch, Button } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { MainMenu } from '../layouts/components/MainMenu'
import { GeneralFooter } from '../layouts/components/GeneralFooter'
import { GeneralContainer } from '../layouts/components/GeneralContainer'
import { getCookieConsentValue, Cookies } from 'react-cookie-consent'
import { initGA } from './../config/config'
import {useCookies} from 'react-cookie'

export const CookiesPolicy: React.FC = () => {

  const isConsent = getCookieConsentValue()==='true' ? true : false;
  const [gaCookie, setGaCookie] = React.useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['CookieConsent']);

  const handleCookies = (mycookie:any) => {
    if (mycookie) {
      initGA();
      setCookie('CookieConsent', true);
    } else {
      Cookies.remove("_ga", { domain: '.otea.info' });
      Cookies.remove("_gat", { domain: '.otea.info' });
      Cookies.remove("_gid", { domain: '.otea.info' });
      Cookies.remove("_ga_HTXCZ1M8F2", { domain: '.otea.info' });
      setCookie('CookieConsent', false);
    }
  }
 
  return (
    <CustomLayout>
      <MainMenu />
      <GeneralContainer>
        <Row gutter={[16, 40]}>
          <Col xs={24}>
            <h1>Condiciones de uso y Política general de privacidad</h1>
            <h2>Configuración de cookies</h2>
            <p>Utilizamos cookies propias y de terceros.</p>
            <p>Algunas de estas cookies son esenciales, mientras que otras nos ayudan a mejorar su experiencia proporcionando información sobre cómo se está utilizando la Web.</p>
            <p>Para información detallada sobre las cookies utilizadas en la Web, consulte la Política de Cookies incluida a continuación</p>
            <hr />
            <p><OTEASwitch disabled={true} defaultChecked /> <b>Cookies esenciales</b><br />Estas cookies son necesarias para el funcionamiento básico del sitio web.</p>
            <CookiesTable>
              <tbody>
                <tr>
                  <td colSpan={4}><b>Cookies propias</b></td>
                </tr>
                <tr>
                  <td>Tipo</td>
                  <td>Cookies</td>
                  <td>Finalidad</td>
                  <td>Duración</td>
                </tr>
                <tr>
                  <td>Preferencias</td>
                  <td>CookieConsent</td>
                  <td>Se usa para guardar la opción determinada por el usuario para la aceptación (o no) de las cookies de OTEA</td>
                  <td>150 días</td>
                </tr>
              </tbody>
            </CookiesTable>
            <p><OTEASwitch defaultChecked={isConsent} onChange={event => setGaCookie(event)} /> <b>Cookies de análisis o medición</b><br />Las cookies analíticas nos ayudan a mejorar la Web recogiendo y reportando información sobre su uso</p>
            <CookiesTable>
              <tbody>
                <tr>
                  <td colSpan={4}><b>Cookies de terceros</b></td>
                </tr>
                <tr>
                  <td>Tipo</td>
                  <td>Cookies</td>
                  <td>Finalidad</td>
                  <td>Duración</td>
                </tr>
                <tr>
                  <td>Análisis o medición</td>
                  <td>_ga</td>
                  <td>Se usa para diferenciar entre usuarios y sesiones por parte de Google Analytics</td>
                  <td>2 años</td>
                </tr>
                <tr>
                  <td>Análisis o medición</td>
                  <td>_gid</td>
                  <td>Crea un identificador único para el rastreo de la sesión por parte de Google Analytics</td>
                  <td>2 años</td>
                </tr>
              </tbody>
            </CookiesTable>
            <CookiesButton type="primary" onClick={event => handleCookies(gaCookie)}>Guardar preferencias</CookiesButton>
            <hr />
            <h2>Información de cookies</h2>
            <p>La Web utiliza archivos cookies propios y de terceros.</p>
            <p>Los archivos cookies y tecnologías similares, tales como web beacons o bugs (en adelante, conjuntamente denominados como “COOKIES”), son archivos o ficheros físicos de información que se almacenan en el dispositivo del USUARIO (ordenador, teléfono móvil, tablet, etc.), inequívocamente asociados a dicho dispositivo, y que permiten la recogida y recuperación de información referente a la navegación que se efectúa desde éste. Para más información sobre COOKIES: https://www.allaboutcookies.org/es/.</p>
            <h3>Tipo y finalidad de las COOKIES utilizados en la Web</h3>
            <p>La Web utiliza COOKIES propias (se envían al equipo terminal del USUARIO desde un equipo o dominio gestionado por BC3 y desde el que éste presta sus servicios) y COOKIES de terceros (se envían al equipo terminal del USUARIO desde un equipo o dominio gestionado por otra entidad ajena a BC3) de las siguientes tipologías: </p>
            <p><b>COOKIES técnicas:</b> Son aquellas que permiten al usuario la navegación a través de la Web y la utilización de las diferentes opciones o servicios disponibles en éste, incluyendo aquellas que utilizadas para permitir la gestión y operativa de la Web y habilitar sus funciones y servicios, como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, gestionar el pago, controlar el fraude vinculado a la seguridad del servicio, o realizar la solicitud de inscripción o participación en un evento.</p>
            <p><b>COOKIES de preferencias o personalización:</b> Son aquéllas que permiten recordar información para que el USUARIO acceda al servicio con determinadas características que pueden diferenciar su experiencia de la de otros USUARIOS, como, por ejemplo, el idioma, el número de resultados a mostrar cuando el usuario realiza una búsqueda, el aspecto o contenido del servicio en función del tipo de navegador utilizado o la región desde la que accede al servicio.</p>
            <p><b>COOKIES de análisis o medición:</b> Son aquéllas que permiten al responsable de éstas el seguimiento y análisis del comportamiento del USUARIO en la Web, incluida la cuantificación de los impactos de los anuncios. La información recogida mediante este tipo de COOKIES se utiliza en la medición de la actividad de la Web con el fin de introducir mejoras en función del análisis de los datos del uso por parte de los USUARIOS.</p>
            <h3>COOKIES de terceros:</h3>
            <p>En todo caso, se hace constar que BC3 no tiene acceso a la información recogida a través de las referidas COOKIES de terceros, al margen de la información agregada y anónima que, en su caso, éstos pongan a su disposición, siendo dichos terceros quienes determinan la finalidad del tratamiento y uso de dicha información.</p>
            <h3>Aceptación, denegación y revocación del consentimiento para el uso de COOKIES</h3>
            <p>Cuando el USUARIO accede por primera vez a la Web, se muestra un aviso que le permitirá (i) aceptar el uso de las COOKIES; (ii) rechazar el uso de las COOKIES (salvo aquéllas estrictamente necesarias); o (iii) configurar sus preferencias a través de la funcionalidad incluida a tal fin en dicho aviso.</p>
            <p>La referida funcionalidad de configuración estará permanentemente accesible para el USUARIO a través de la “Política de Cookies” y le permitirá visualizar, en cualquier momento, el tipo de cookies y su función, seleccionar la instalación de COOKIES conforme a sus preferencias y revocar el consentimiento anteriormente prestado.</p>
            <p>Tenga en cuenta que, si acepta las COOKIES de terceros, deberá eliminarlas desde las opciones del navegador o desde el sistema ofrecido por el propio tercero. Puede encontrar más información al respecto en los siguientes enlaces:</p>
            <p><Link to="https://tools.google.com/dlpage/gaoptout?hl=es">Inhabilitar COOKIES de Google Analytics</Link>.</p>
            <p>Configuración de navegadores:</p>
            <ul>
              <li><Link to="https://support.google.com/chrome/answer/95647?hl=es">Google Chrome</Link></li>
              <li><Link to="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</Link></li>
              <li><Link to="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-?redirectlocale=en-US&redirectslug=Cookies">Firefox</Link></li>
              <li><Link to="https://support.apple.com/es-es/guide/safari/sfri11471/mac">Safari</Link></li>
              <li><Link to="https://support.apple.com/es-es/HT201265">Safari para IOS (Iphone y Ipad)</Link></li>
            </ul>
            <p>la Web es accesible sin necesidad de que estén activadas las opciones referentes a COOKIES. Ello no obstante, el rechazo o desinstalación de COOKIES puede hacer que el acceso a determinados contenidos y el uso de algunos servicios puestos a disposición del USUARIO a través de la Web no sean plenamente funcionales. </p>
            <h3>Protección de datos</h3>
            <p>Cualquier tratamiento de datos realizado por BC3 como consecuencia del uso de COOKIES propias se regirá por lo dispuesto en la <Link to="/politica-privacidad">Política de privacidad</Link>.</p>
            <p>Puede informarse de las transferencias internacionales a terceros países que, en su caso, realizan los terceros identificados en esta “Política de Cookies” y sobre el resto de los aspectos relativos al tratamiento de sus datos por parte de éstos en sus correspondientes políticas de privacidad (ver los enlaces facilitados en el Apartado relativo a las COOKIES de terceros).</p>
          </Col>
        </Row>
      </GeneralContainer>
      
      <GeneralFooter />
    </CustomLayout>
  )
}

const CustomLayout = styled(Layout)`
  background: #fff;
  padding-top: 150px;
`

const CookiesButton = styled(Button)`
  margin-bottom: 40px;
  background: #30cdde;
  border: 1px solid #30cdde;
`

const OTEASwitch = styled(Switch)`
  &.ant-switch-checked {
    background: #30cdde;
  }
`

const CookiesTable = styled.table`
  border: 1px solid #ddd;
  width: 100%;
  margin-bottom: 40px;
  font-size: small;

  tr:nth-child(2) {
    background: #f0f0f0;
  }

  td {
    padding: 8px;

    &:nth-child(1) {
      width: 150px;
    }

    &:nth-child(2) {
      width: 100px;
    }

    &:last-child {
      width: 100px;
    }
  }
`