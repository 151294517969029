import React from 'react'
import { Res, useFetch } from 'use-http'
import { SelectedSeries } from '../components/Chart'
import { getAbsoluteServerUrl } from './config'

export enum ChartTypesEnum {
  Line = 'line',
  Column1Y = 'column1Y',
  ColumnAllY = 'column',
  ColumnStacked = 'column_stacked',
  Area = 'area',
  Spline = 'spline',
  Percentage = 'percentage',
  Pie = 'pie',
  Bar = 'bar',
  Map = 'map',
  List = 'list',
  Waterfall = 'waterfall',
}

export enum UnitsEnum {
  tCO2eq = 'tCO2eq',
  ktCO2eq = 'ktCO2eq',
  MtCO2eq = 'MtCO2eq',
  MtCO2eqPerEur = 'MtCO2eq/€',
  tCO2eqPerPerson = 'tCO2eq/persona',
}

type RawIndicator = {
  owner: string
  title: string
  long_title: string
  code: string
  level: string
  source: string
  source_link: string
  source2: string
  source2_link: string
  derivation: string
  periodicity: string
  available_range_from: string
  available_range_to: string
  estimated_delay: string
  definition: string
  description: string
  unit: string
  unit2: string
  unit3: string
  multiplier_1to2: number
  multiplier_1to3: number
  start_from_zero: string
  equivalent_units: string
  chart_area: number
  chart_percent: number
  chart_line: number
  chart_pie: number
  chart_bar: number
  chart_column: number
  chart_column_stacked: number
  chart_map: number
  chart_waterfall: number
  dashed: number
  remarks: string
  internal_comments: string
  spatial_disaggregation: string
  temporal_disaggregation: string
  eu_comparison: string
  default_chart: string
  default_year: string
  export_file_name: string
}

type Section = {
  num: number
  title: string
  code: string
  indicators: Indicator[]
}

export type Source = {
  name: string
  link: string
}

export type Indicator = {
  owner: string
  title: string
  longTitle: string
  code: string
  units: UnitsEnum[]
  multiplier1to2: number
  multiplier1to3: number
  startFromZero: boolean
  chartTypes: ChartTypesEnum[]
  series: SeriesDefinition[]
  sources: Source[]
  derivation: boolean
  description: string
  availableRange: number[]
  periodicity: string
  exportFileName: string
  defaultChart?: ChartTypesEnum
  defaultYear?: string
}

type SeriesDefinition = {
  name: string
  code: string
  sources: Source[]
  dashed: boolean
  derivation: boolean
  excludedTypes: ChartTypesEnum[]
}

export type WaterfallData = { name?: string; y: number; isSum: boolean }

export type FinalSeries = { name: string; data: (number | WaterfallData)[] }

//getAbsoluteServerUrl('/charts/chart-list.json')

export function useGetIndicators() {
  return useFetch<Section[]>(
    getAbsoluteServerUrl('/indicators/list'),
    {
      interceptors: {
        response: async ({ response }): Promise<Res<Indicator[]>> => {
          const res = response
          try {
            res.data = Object.values(
              res.data.reduce((prev: { [key: string]: Section }, rawIndicator: RawIndicator) => {
                const level = Number(rawIndicator.level)
                const sectionCode = rawIndicator.code.substr(0, 2)
                const indicatorCode = rawIndicator.code.substr(0, 3)

                const section: Section = prev[sectionCode]
                  ? prev[sectionCode]
                  : {
                      num: Number(rawIndicator.owner),
                      title: rawIndicator.title,
                      code: rawIndicator.code,
                      indicators: [],
                    }

                const selectedIndicator = section.indicators.find(i => i.code === indicatorCode)
                const chartTypes = [
                  rawIndicator.chart_area ? ChartTypesEnum.Area : undefined,
                  rawIndicator.chart_percent ? ChartTypesEnum.Percentage : undefined,
                  rawIndicator.chart_line ? ChartTypesEnum.Line : undefined,
                  rawIndicator.chart_pie ? ChartTypesEnum.Pie : undefined,
                  rawIndicator.chart_bar ? ChartTypesEnum.Bar : undefined,
                  rawIndicator.chart_column ? ChartTypesEnum.ColumnAllY : undefined,
                  rawIndicator.chart_column_stacked ? ChartTypesEnum.ColumnStacked : undefined,
                  rawIndicator.chart_map ? ChartTypesEnum.Map : undefined,
                  rawIndicator.chart_waterfall ? ChartTypesEnum.Waterfall : undefined,
                  ChartTypesEnum.List,
                ].filter(i => !!i) as ChartTypesEnum[]

                const excludedChartTypes = [
                  rawIndicator.chart_area === 0 ? ChartTypesEnum.Area : undefined,
                  rawIndicator.chart_percent === 0 ? ChartTypesEnum.Percentage : undefined,
                  rawIndicator.chart_line === 0 ? ChartTypesEnum.Line : undefined,
                  rawIndicator.chart_pie === 0 ? ChartTypesEnum.Pie : undefined,
                  rawIndicator.chart_bar === 0 ? ChartTypesEnum.Bar : undefined,
                  rawIndicator.chart_column === 0 ? ChartTypesEnum.ColumnAllY : undefined,
                  rawIndicator.chart_column_stacked === 0 ? ChartTypesEnum.ColumnStacked : undefined,
                  rawIndicator.chart_map === 0 ? ChartTypesEnum.Map : undefined,
                  rawIndicator.chart_waterfall === 0 ? ChartTypesEnum.Waterfall : undefined,
                ].filter(i => !!i) as ChartTypesEnum[]

                /*if (!(rawIndicator.units in UnitsEnum)) {
                throw Error(`${rawIndicator.units} unit not recognized`)
              }*/

                const newIndicator: Indicator | undefined = selectedIndicator
                  ? undefined
                  : {
                      owner: rawIndicator.owner,
                      title: rawIndicator.title,
                      longTitle: rawIndicator.long_title,
                      code: rawIndicator.code,
                      units: [
                        rawIndicator.unit as UnitsEnum,
                        rawIndicator.unit2 as UnitsEnum,
                        rawIndicator.unit3 as UnitsEnum,
                      ].filter(i => !!i),
                      multiplier1to2: rawIndicator.multiplier_1to2,
                      multiplier1to3: rawIndicator.multiplier_1to3,
                      startFromZero: !!rawIndicator.start_from_zero,
                      chartTypes,
                      series: [],
                      description: rawIndicator.description,
                      derivation: rawIndicator.derivation === 'y',
                      sources: [
                        {
                          name: rawIndicator.source,
                          link: rawIndicator.source_link,
                        },
                        {
                          name: rawIndicator.source2,
                          link: rawIndicator.source2_link,
                        },
                      ].filter(f => !!f.name),
                      availableRange: [
                        Number(rawIndicator.available_range_from),
                        Number(rawIndicator.available_range_to),
                      ],
                      periodicity: rawIndicator.periodicity,
                      exportFileName: rawIndicator.export_file_name,
                      defaultChart: rawIndicator.default_chart
                        ? (rawIndicator.default_chart as ChartTypesEnum)
                        : undefined,
                      defaultYear: rawIndicator.default_year,
                    }

                const newSeries: SeriesDefinition = {
                  name: rawIndicator.title,
                  code: rawIndicator.code,
                  derivation: rawIndicator.derivation === 'y',
                  sources: [
                    {
                      name: rawIndicator.source,
                      link: rawIndicator.source_link,
                    },
                    {
                      name: rawIndicator.source2,
                      link: rawIndicator.source2_link,
                    },
                  ].filter(f => !!f.name),
                  excludedTypes: excludedChartTypes,
                  dashed: rawIndicator.dashed === 1,
                }

                const indicator = selectedIndicator
                  ? { ...selectedIndicator, series: [...selectedIndicator.series, newSeries] }
                  : newIndicator

                const sectionWithIndicators = {
                  ...section,
                  ...(level > 1
                    ? { indicators: [...section.indicators.filter(i => i.code !== indicator?.code), indicator] }
                    : {}),
                }

                return {
                  ...prev,
                  [sectionCode]: sectionWithIndicators,
                }
              }, {}),
            )
          } catch (e) {
            console.log(e)
          }

          console.log(res)

          return res
        },
      },
      responseType: 'json',
    },
    [],
  )
}

export const isSingleYearType = (type: ChartTypesEnum) => {
  return (
    type === ChartTypesEnum.Bar ||
    type === ChartTypesEnum.Pie ||
    type === ChartTypesEnum.Map ||
    type === ChartTypesEnum.Waterfall
  )
}

export const isRelativeType = (type: ChartTypesEnum) => {
  return type === ChartTypesEnum.Percentage
}

export const chartTypeMap = (chartType: ChartTypesEnum) => {
  if ([ChartTypesEnum.Column1Y, ChartTypesEnum.ColumnStacked].includes(chartType)) {
    return ChartTypesEnum.ColumnAllY
  }

  if (chartType === ChartTypesEnum.Percentage) {
    return ChartTypesEnum.Area
  }

  return chartType
}

export const getSeries = (selectedIndicator: Indicator, key: string) => {
  const series = selectedIndicator.series.find(item => item.code === key)
  if (!series) {
    throw new Error(`Error trying to find series ${key}`)
  }

  return series
}

export type ChartData = {
  data: {
    series: { name: string; data: { year: number; value: number }[] }[]
  }
}
export type Series = {
  units: string
  key: string
  values: { year: number; value: number }[]
}

export const transformRawIndicatorData = (rawData: any): { [key: string]: Series[] } => {
  const series: Series[] = rawData.map((row: any) => {
    const years = Object.entries(row)
      .filter(([key]) => !['Indicador', 'Unidades'].includes(key))
      .map(([year, value]) => ({
        year: Number.isNaN(Number(year)) ? year : Number(year),
        // value: value === '' ? null : Number((value as string).replace(',', '.').replace('%', '')),
        value: value === '' ? null : value,
      }))

    return {
      units: row.Unidades,
      key: row.Indicador,
      values: years,
    }
  })

  const groupedSeries = series.reduce((prev: { [key: string]: Series[] }, current) => {
    const subIndicatorKey = current.key.substr(0, 3)
    return {
      ...prev,
      [subIndicatorKey]: prev[subIndicatorKey] ? [...prev[subIndicatorKey], current] : [current],
    }
  }, {})

  // Remove years with no values on the extremes
  return Object.entries(groupedSeries).reduce((prev, [key, localSeries]) => {
    const maxX = Math.max(
      ...localSeries.map(s => Math.max(...s.values.filter(v => v.value !== null).map(v => Number(v.year)))),
    )
    const minX = Math.min(
      ...localSeries.map(s => Math.min(...s.values.filter(v => v.value !== null).map(v => Number(v.year)))),
    )
    return {
      ...prev,
      [key]: localSeries.map(s => ({
        ...s,
        values: s.values.filter(p => Number(p.year) >= minX && Number(p.year) <= maxX),
      })),
    }
  }, {})
}

export const transformSeries = (
  series: { name: string; dashStyle?: string; data: number[] }[],
  chartType: ChartTypesEnum,
) => {
  if (chartType === ChartTypesEnum.Column1Y) {
    return series.map(item => ({
      ...item,
      data: [item.data[item.data.length - 1]],
    }))
  }

  if (chartType === ChartTypesEnum.Waterfall) {
    return series.map(item => ({
      ...item,
      data: item.data.map((y, index) => ({
        y,
        isSum: index === item.data.length - 1,
      })),
    }))
  }

  return series
}

export const chartTypes: { [key in ChartTypesEnum]: { name: string } } = {
  [ChartTypesEnum.Spline]: { name: 'Líneas Suaves' },
  [ChartTypesEnum.Line]: { name: 'Líneas' },
  [ChartTypesEnum.Column1Y]: { name: 'Columnas 1 año' },
  [ChartTypesEnum.ColumnAllY]: { name: 'Columnas' },
  [ChartTypesEnum.ColumnStacked]: { name: 'Apilada' },
  [ChartTypesEnum.Area]: { name: 'Áreas' },
  [ChartTypesEnum.Percentage]: { name: 'Relativa' },
  [ChartTypesEnum.Bar]: { name: 'Barras' },
  [ChartTypesEnum.Pie]: { name: 'Circular' },
  [ChartTypesEnum.Map]: { name: 'Mapa' },
  [ChartTypesEnum.List]: { name: 'Tabla' },
  [ChartTypesEnum.Waterfall]: { name: 'Waterfall' },
}

export const getMaxCategory = (series: SelectedSeries[]) => {
  return Math.max(...series.map(s => Math.max(...s.values.filter(v => v.value !== null).map(v => Number(v.year)))))
}

export const getMax = (series: SelectedSeries[], chartType: ChartTypesEnum) => {
  if (chartType === ChartTypesEnum.Waterfall) {
    return Math.max(
      ...series[0].values.map((v, index) => {
        return series.reduce(
          (prev, current, seriesIndex) => {
            const accumulated = prev.accumulated + (seriesIndex === series.length - 1 ? 0 : current.values[index].value)
            return { max: Math.max(prev.max, accumulated), accumulated }
          },
          { max: 0, accumulated: 0 },
        ).max
      }),
    )
  }

  return Math.max(...series.map(s => Math.max(...s.values.map(v => v.value))))
}

export const getMin = (series: SelectedSeries[]) => {
  return Math.min(...series.map(s => Math.min(...s.values.map(v => v.value))))
}

export const areas: { [key: string]: { title: string; icon: JSX.Element } } = {
  C: { title: 'Clima y Medioambiente', icon: <img className="iconBDMenu" alt="Clima" src="/iconos/icono-clima.png" /> },
  E: { title: 'Energía', icon: <img className="iconBDMenu" alt="Clima" src="/iconos/icono-energia.png" /> },
  D: { title: 'Economía', icon: <img className="iconBDMenu" alt="Clima" src="/iconos/icono-economia.png" /> },
  S: { title: 'Sociedad', icon: <img className="iconBDMenu" alt="Clima" src="/iconos/icono-sociedad.png" /> },
}

export const getArea = (code: string) => {
  return areas[code] || ''
}
