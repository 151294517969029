import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'antd'
import { Timeline } from 'react-twitter-widgets'
import { Link } from 'react-router-dom'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { initGA } from '../../config/config'
import { GeneralContainer } from './GeneralContainer'

const handleAcceptCookie = () => {
  initGA()
}

const handleDeclineCookie = () => {
  Cookies.remove('_ga', { domain: '.otea.info' })
  Cookies.remove('_gat', { domain: '.otea.info' })
  Cookies.remove('_gid', { domain: '.otea.info' })
  Cookies.remove('_ga_HTXCZ1M8F2', { domain: '.otea.info' })
}

export const GeneralFooter: React.FC = () => {
  return (
    <Footer>
      <GeneralContainer>
        <Row gutter={[16, 40]}>
          <Col xs={12} md={6}>
            <img
              src="/logos/vertical.png"
              alt="OTEA: Observatorio para la Transición Energética y la Acción Climática"
              width="120px"
            />
          </Col>
          <Col xs={12} md={9}>
            <h4>Contacto:</h4>
            <p>
              Edificio Sede 1, Planta 1<br />
              Parque Científico UPV/EHU, B/Sarriena s/n
              <br />
              48940 Leioa, Bizkaia
            </p>
            <p>
              +34 944 014 690
              <br />
              contacto@otea.info
            </p>
            <p>
              <Link to="/politica-privacidad">Política de privacidad</Link>
              <br />
              <Link to="/cookies">Política de cookies</Link>
            </p>
          </Col>
          <Col xs={24} md={9}>
            <Timeline
              dataSource={{
                sourceType: 'profile',
                screenName: 'observatoriOTEA',
              }}
              options={{
                height: '300',
                width: '100%',
              }}
            />
          </Col>
        </Row>
        <CookieConsent
          buttonText="Aceptar"
          expires={150}
          declineButtonText="Rechazar"
          onAccept={handleAcceptCookie}
          onDecline={handleDeclineCookie}
          enableDeclineButton>
          <p>
            Utilizamos cookies propias y de terceros para adaptar el sitio web a sus hábitos de navegación y obtener
            datos estadísticos de su utilización. Puede obtener más información sobre ellas y configurar sus
            preferencias en la <Link to="/cookies">Política de cookies</Link>.
          </p>
        </CookieConsent>
      </GeneralContainer>
    </Footer>
  )
}

const Footer = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  background: #efefef;
  padding-top: 40px;
  margin-top: 80px;
`
