import React, { useState } from 'react'
import { Url } from 'url'
import { useFetch } from 'use-http'
import { getAbsoluteServerUrl } from '../config/config'
import { Row, Layout, Pagination } from 'antd'
import styled from 'styled-components'
import { MainMenu } from '../layouts/components/MainMenu'
import { SmallVisor } from '../layouts/components/SmallVisor'
import { BlogPost } from '../layouts/components/BlogPost'
import { GeneralContainer } from '../layouts/components/GeneralContainer'
import { GeneralFooter } from '../layouts/components/GeneralFooter'
import { Helmet } from "react-helmet";

type BlogArchive = { 
  current_page: number;
  total: number; 
  first_page_url: Url; 
  last_page: number; 
  last_page_url: Url; 
  next_page_url: Url; 
  prev_page_url: Url;
  links: { 
    url: string; 
    label: string; 
    active: boolean 
  }[]; 
  data: { 
    id: number; 
    title: string;
    description: string;
    url: string; 
    body: string; 
    updated_at: Date 
  }[] 
}

export const Blog: React.FC = () => {

  const [page, setPage] = useState(1)
  const {data = [], loading} = useFetch<BlogArchive[]>(getAbsoluteServerUrl(`/articles?page=${page}`), {onNewData: (currArticles, newArticles) => [ newArticles ]}, [page])

  const blogArchive = data[0] || []
  const blogPosts = blogArchive.data || []

  return (
    <CustomLayout>
      <Helmet>
        <title>Noticias sobre el Observatorio de la transición energética</title>
        <meta name="description" content="Artículos y noticias sobre el Observatorio de la transición energética y la acción climática" />
        <meta name="keywords" content="Artículos, noticias, estudios, publicaciones, blog, post, transición energética, energía, sociedad, economía, objetivos energéticos, acción climática" />
        <meta name="author" content="OTEA: Observatorio de la transición energética y la acción climática" />
      </Helmet>
      <MainMenu />
      <SmallVisor pageTitle="Artículos OTEA" />

      <GeneralContainer>
        <Row>
          {blogPosts.map(post => (
            <BlogPost key={post.id}
              postId={post.id}
              postDescription={post.description}
              postBody={post.body}
              postDate={post.updated_at}
              postTitle={post.title}
              postUrl={post.url} />
          ))}
        </Row>
        <StyledPagination onChange={(pag) => setPage(pag)} defaultCurrent={1} total={blogArchive.total} pageSize={5} current={blogArchive.current_page} hideOnSinglePage />
      </GeneralContainer>
      <GeneralFooter />
    </CustomLayout>
  )
}
const CustomLayout = styled(Layout)`
  background: #fff;
`

const StyledPagination = styled(Pagination)`
  .ant-pagination-item-active {
    border-color: #30cdde;
  }

  .ant-pagination-item:focus-visible, .ant-pagination-item:hover, .ant-pagination-item-link:focus-visible, .ant-pagination-item-link:hover {
    border-color: #30cdde;
  }

  a, button {
  transition: all .1s linear;

  &:hover {
      background: #30cdde;
      color: #FFF;
    }
  }
`
