import React from 'react'
import { Col, Layout, Row, Card } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { MainMenu } from '../layouts/components/MainMenu'
import { GeneralFooter } from '../layouts/components/GeneralFooter'
import { BigVisor } from '../layouts/components/BigVisor'
import { GeneralContainer } from '../layouts/components/GeneralContainer'
import { DownloadModule } from '../layouts/components/DownloadModule'
import { SubscribeNewsModule } from '../layouts/components/SubscribeNewsModule'
import { useGetHomeIndicators } from '../services/home-indicators.service'
import { HomeIndicatorCard } from '../layouts/components/HomeIndicatorCard'

const { Meta } = Card

export const Home: React.FC = () => {

  const homeIndicatorsRequest = useGetHomeIndicators()
  const homeIndicators = homeIndicatorsRequest.data || []

  return (
    <CustomLayout>
      <MainMenu />
      <BigVisor />
      <GeneralContainer>
          <Row gutter={[16, 40]}>
            <Col xs={24} md={15}>
              <h2>La transición a tu alcance</h2>
              <p>
                En OTEA queremos brindarte una información fiable, veraz y actualizada sobre la transición energética y
                la acción climática en España, un contenido estructurado de forma clara y de fácil acceso.
              </p>
            </Col>
            <Col xs={24} md={{ span: 8, offset: 1 }}>
              <Row gutter={[16, 16]}>
                <DownloadModule />
              </Row>
            </Col>
          </Row>
      </GeneralContainer>
      <GeneralContainer>
        <Row gutter={[26, 40]}>
          <Col xs={24}>
            <h2>Seguimiento de los objetivos del PNIEC</h2>
          </Col>
        </Row>
        <Row gutter={[16, 40]}>
          {homeIndicators.map(i => (
            <Col xs={24} md={12} xl={8} key={i.id}>
              <HomeIndicatorCard
                title={i.title}
                icon={i.attachment[0].url}
                firstYear={i.firstYear}
                firstValue={i.firstValue}
                secondYear={i.secondYear}
                secondValue={i.secondValue}
                trend={i.trend}
                description={i.description}
                url={i.url}
              />
            </Col>
          ))}
        </Row>
        <Row>
          <Col xs={24}>
            <div className='home-indicators-legend'>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L9.29289 9.29289C9.68342 8.90237 10.3166 8.90237 10.7071 9.29289L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071ZM4.29289 9.70711C3.90237 9.31658 3.90237 8.68342 4.29289 8.29289L9.29289 3.29289C9.68342 2.90237 10.3166 2.90237 10.7071 3.29289L15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711C15.3166 10.0976 14.6834 10.0976 14.2929 9.70711L10 5.41421L5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711Z" fill="#ffaa00"/></svg> En la trayectoria hacia el objetivo<br/>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L10.7071 10.7071C10.3166 11.0976 9.68342 11.0976 9.29289 10.7071L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289ZM15.7071 10.2929C16.0976 10.6834 16.0976 11.3166 15.7071 11.7071L10.7071 16.7071C10.3166 17.0976 9.68342 17.0976 9.29289 16.7071L4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929C4.68342 9.90237 5.31658 9.90237 5.70711 10.2929L10 14.5858L14.2929 10.2929C14.6834 9.90237 15.3166 9.90237 15.7071 10.2929Z" fill="#374151"/></svg> No sigue la trayectoria
            </div>
          </Col>
        </Row>
      </GeneralContainer>
      <GeneralContainer>
        <Row gutter={[26, 40]}>
          <Col xs={24}>
            <h2>Explora nuestra base de datos</h2>
          </Col>
        </Row>
        <Row gutter={[16, 40]}>
          <Col xs={24} md={6}>
            <Link to="/bdd/C3/C31">
              <HomeCard cover={<img alt="Clima" src="/iconos/icono-clima.png" />}>
                <Meta title="Clima" />
              </HomeCard>
            </Link>
          </Col>
          <Col xs={24} md={6}>
            <Link to="/bdd/E1/E11">
              <HomeCard cover={<img alt="Energía" src="/iconos/icono-energia.png" />}>
                <Meta title="Energía" />
              </HomeCard>
            </Link>
          </Col>
          <Col xs={24} md={6}>
            <Link to="/bdd/D1/D11">
              <HomeCard cover={<img alt="Economía" src="/iconos/icono-economia.png" />}>
                <Meta title="Economía" />
              </HomeCard>
            </Link>
          </Col>
          <Col xs={24} md={6}>
            <Link to="/bdd/S1/S11">
              <HomeCard cover={<img alt="Sociedad" src="/iconos/icono-sociedad.png" />}>
                <Meta title="Sociedad" />
              </HomeCard>
            </Link>
          </Col>
        </Row>
      </GeneralContainer>
      <GeneralContainer>
        <Row>
          <SubscribeNewsModule/>
        </Row>
      </GeneralContainer>
      <GeneralFooter />
    </CustomLayout>
  )
}

const HomeCard = styled(Card)`
  text-align: center;
  padding: 10px;
  border-radius: 100px;
  border: 0;
  max-width: 180px;
  margin: 40px auto 0;
  display: flex;
  flex-direction: column;

  img {
    margin: 0 auto;
    width: 100px;
  }

  .ant-card-cover {
    display: flex;
    align-items: center;
    height: 100px;
  }
`

const CustomLayout = styled(Layout)`
  background: #fff;

  .home-indicators-legend {
    width: 100%;
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 5px;
    font-size: small;
    margin: 20px 0 30px;

    svg {
      position: relative;
      top: 5px;
    }
  }
`
