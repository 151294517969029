import React from 'react'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import reactStringReplace from 'react-string-replace'
import { Chart } from '../../components/Chart'

type Props = {
  postId: number
  postTitle: string
  postDescription: string
  postUrl: string
  postBody: string
  postDate: Date
}

export const BlogPost: React.FC<Props> = ({ postTitle, postUrl, postDescription, postBody, postDate }) => {
  const formatDate = (d: Date) => {
    const date = new Date(d)

    return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_FULL)
  }

  const encodedURL = encodeURIComponent(postUrl)

  const regexComp = new RegExp('{chart id="([^"]+)"}', 'igs')
  const regexRes = [...postBody.matchAll(regexComp)]

  const rawRegex = new RegExp('{iframe url="([^}]+)"}', 'igs')
  const rawRegexResults = [...postBody.matchAll(rawRegex)]

  const bodyWithCharts =
    regexRes.length > 0
      ? reactStringReplace(postBody, regexComp, (match, i) => (
          <Chart key={i} chart={match.substring(0, 2)} subChart={match} />
        ))
      : [postBody]

  const bodyWithIframes =
    rawRegexResults.length > 0
      ? reactStringReplace(bodyWithCharts, rawRegex, (match, i) => (
          <iframe
            src={match}
            style={{
              width: '100%',
              height: 600,
              border: '0px none',
            }}
          />
        ))
      : bodyWithCharts

  return (
    <Post>
      <Helmet>
        <title>{postTitle}</title>
        <meta name="description" content={postDescription} />
        <meta
          name="keywords"
          content="Artículos, noticias, estudios, publicaciones, blog, post, transición energética, energía, sociedad, economía, objetivos energéticos, acción climática"
        />
        <meta name="author" content="OTEA: Observatorio de la transición energética y la acción climática" />
      </Helmet>
      <header>
        <h2>
          <Link to={`/noticias/${encodedURL}`}>{postTitle}</Link>
        </h2>
        <small>Publicado el {formatDate(postDate)}</small>
      </header>
      <article>
        {bodyWithIframes.map(n =>
          typeof n === 'object' ? n : <div key={n as string} dangerouslySetInnerHTML={{ __html: n as string }} />,
        )}
      </article>
    </Post>
  )
}

const Post = styled.div`
  margin-bottom: 80px;
  width: 100%;

  h2 {
    border-bottom: 1px solid #efefef;
    margin-bottom: 5px;
  }

  header small {
    font-weight: bold;
  }

  header {
    margin-bottom: 40px;
  }

  img {
    float: right;
    max-width: 300px;
    padding: 10px;
    border: 1px solid #eee;
    margin: 10px;
  }
`
