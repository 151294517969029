import React from 'react'
import styled from 'styled-components'
import { Select } from 'antd'
import { useHistory } from 'react-router-dom'
import { SelectValue } from 'antd/lib/select'
import { useGetIndicators } from '../../services/indicators.service'

export const DBSearch: React.FC = () => {
  const indicatorsRequest = useGetIndicators()
  const indicators = indicatorsRequest.data || []
  const { Option } = Select
  const history = useHistory()

  const onChange = (value: SelectValue) => {
    const selectedIndicator = indicators.find(obj => {
      return obj.long_title === value
    })
    history.push(`/bdd/${selectedIndicator?.code.substring(0, 2)}/${selectedIndicator?.code}`)
  }

  return (
    <SearchBar
      showSearch
      placeholder="Escribe algo para buscar"
      optionFilterProp="children"
      onChange={onChange}
      filterOption={(input, option) =>
        (option!.value as unknown as string)
          .toString()
          .toLowerCase()
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '')
          .includes(
            input
              .toString()
              .toLowerCase()
              .normalize('NFD')
              .replace(/\p{Diacritic}/gu, ''),
          )
      }>
      {indicators.map((se: any) => (
        <Option key={se.id} value={se.long_title}>
          {se.long_title}
        </Option>
      ))}
    </SearchBar>
  )
}

const SearchBar = styled(Select)`
  width: 100%;
  margin: 40px 0;
`
