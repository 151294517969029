import React from 'react'
import { Col, Layout, Row } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { MainMenu } from '../layouts/components/MainMenu'
import { GeneralFooter } from '../layouts/components/GeneralFooter'
import { GeneralContainer } from '../layouts/components/GeneralContainer'

export const PrivacyPolicy: React.FC = () => {

  return (
    <CustomLayout>
      <MainMenu />
      <GeneralContainer>
        <Row gutter={[16, 40]}>
          <Col xs={24}>
            <div>
              <h1>Condiciones de uso y Política general de privacidad</h1>
              <p>BC3 Basque Centre for Climate Change - Klima Aldaketa Ikergai, en adelante BC3, responsable del sitio web, en cumplimiento de las obligaciones dispuestas en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico pone a disposición de los usuarios las condiciones de uso del sitio web y se reserva el derecho a modificar su contenido sin necesidad de previo aviso.</p>
              <p>El acceso a esta página supone la aceptación de las presentes condiciones de uso.</p>
              <h2>1. DATOS IDENTIFICATIVOS</h2>
              <p>Denominación social: BC3 Basque Centre for Climate Change-Klima Aldaketa Ikergai 
              <br/>CIF: G95532826
              <br/>Domicilio: Edificio Sede 1, 1º Planta 1 - Parque Cientifico Upv/Ehu B/Sarriena s/n, 48940 Leioa, Bizkaia.
              <br/>e-mail: info@bc3research.org</p>
              <h2>3. CONDICIONES DE USO</h2>
              <p>El uso del sitio web implica la aceptación completa de todas las cláusulas y condiciones de uso incluidas en las páginas:</p>
              <ul>
                <li>Aviso Legal</li>
                <li>Política de Privacidad</li>
                <li>Política de Cookies</li>
              </ul>
              <p>Si no estuviera conforme con todas y cada una de estas cláusulas y condiciones absténgase de navegar en www.otea.info , en adelante Web y/o Web OTEA.</p>
              <h3>Acceso y uso del sitio Web</h3>
              <p>No se exige suscripción o registro como usuario para el acceso y uso de la página Web, sin perjuicio de que para la utilización de determinados servicios o contenidos de la misma sea necesaria la suscripción o registro.</p>
              <p>En caso de que se requiera la suscripción o registro a alguno de los contenidos del presente sitio web, BC3 adoptará las medidas técnicas y organizativas necesarias para garantizar la protección de los datos de carácter personal y evitar su alteración, pérdida, tratamiento y/o acceso no autorizado, conforme a lo establecido por la legislación española de Protección de Datos de Carácter Personal conforme a lo establecido en la Política de Privacidad que puede encontrar en el epígrafe 3 de las presentes condiciones. </p>
              <p>El usuario se compromete a hacer un uso adecuado y lícito del sitio web, así como de los contenidos y servicios, de conformidad con la legislación vigente aplicable y las presentes condiciones. En este sentido, el usuario debe abstenerse de hacer un uso no autorizado o fraudulento del sitio Web y/o de los contenidos del mismo con fines o efectos ilícitos y/o prohibidos en las presentes Condiciones.</p>
              <p>BC3 está facultada para suspender temporalmente o permanentemente, y sin previo aviso, la accesibilidad al sitio web con motivo de operaciones de mantenimiento, reparación, actualización o mejora.</p>

              <h3>Responsabilidad y Enlaces de terceros</h3>
              <p>Cualquier contenido del sitio web es puede ser objeto de ampliaciones, correcciones y actualizaciones. Aunque BC3 empleará la máxima diligencia para proporcionar una información actualizada y exacta, BC3 no garantiza la actualización y exactitud de la misma. BC3 no asume ningún tipo de responsabilidad respecto de los errores, omisiones y obsolescencia del sitio web. </p>
              <p>BC3 no se hace responsable de los daños y perjuicios que pudieran derivarse de la utilización de la información del sitio web por parte de los usuarios o terceros.</p>
              <p>Las presentes Condiciones se refieren únicamente al sitio Web OTEA, y no se aplica a los posibles enlaces o a páginas Web de terceros accesibles a través del presente sitio web. Los destinos de dichos enlaces no están bajo el control de BC3, y no es responsable del contenido de ninguna de las páginas web de destino, ni de ningún enlace incluido en una página web a la que se llegue.</p>
              <p>Los enlaces se proporcionan únicamente para informar al usuario sobre la existencia de otras fuentes de información, la inclusión de un enlace no implica la aprobación de la web enlazada por parte de BC3.</p>
              <h3>Propiedad intelectual e industrial</h3>
              <p>Todos los contenidos del sitio web, salvo que se indique lo contrario, son titularidad o uso exclusivo de BC3, con carácter enunciativo, que no limitativo, diseño gráfico, logos, textos, gráficos, ilustraciones, fotografías y están protegidos por Ley. Bajo ningún concepto se entenderá que, por el acceso a esta página web, el usuario obtenga ningún tipo de licencia o autorización de uso personal sobre sus derechos de propiedad intelectual e industrial o sobre cualquier otro derecho relacionado el sitio web y los servicios ofrecidos en la misma. Por ello, el usuario reconoce que la reproducción, distribución, comercialización, transformación, y en general, cualquier otra forma de explotación, por cualquier procedimiento, de todo o parte de los contenidos de esta Web constituye una infracción de los derechos de propiedad intelectual y/o industrial de BC3 o del titular de los mismos.</p>
              <p>Por lo tanto, cualquier uso o explotación de cualesquiera derechos estará sujeto a la previa y expresa autorización específicamente otorgada a tal efecto por BC3 o el tercero titular de los derechos afectados.</p>
              <h2>3. PRIVACIDAD Y TRATAMIENTO DE DATOS</h2>
              <p>Su privacidad y la seguridad de sus datos personales son muy importantes para nosotros. Por ello, le instamos a que lea detenidamente esta Política de privacidad para conocer como BC3 tratará aquellos datos personales que puedan ser recabados por el uso de la Web o a través de los distintos formularios que puedan encontrarse en el sitio web https://www.otea.info/. Tenga en cuenta que sus datos personales serán tratados de forma confidencial y solo para aquellas finalidades que usted haya consentido.</p>
              <p>Para obtener más información sobre el uso de cookies en nuestra página web, consulte nuestra <Link to="/cookies">Política de cookies</Link>.</p>
              <h3>Responsable del tratamiento</h3>
              <p>Identidad: Basque Centre for Climate Change (BC3)</p>
              <p>Dirección postal: Edificio Sede, Campus EHU, Barrio Sarriena, s/n, 48940 Lejona, Vizcaya</p>
              <p>Datos de contacto del Responsable de los tratamientos: ARCrights@BC3research.org</p>
              <h3>Finalidades del tratamiento de los datos</h3>
              <p>BC3 recaba sus datos personales unicamente para ofrecer una web funcional y operativa y, en su caso, para poder gestionar sus solicitudes y ofrecerle los servicios a los que usted haya consentido. En este sentido, BC3 se compromete a procesar sus datos con acuerdo a las regulaciones legales española y europea.</p>
              <h3>Tratamiento de sus datos</h3>
              <p>Visitas al sitio web: Cada vez que acceda al sitio web el sistema recopila automáticamente información tal como:</p>
              <ul>
                <li>Dirección IP.</li>
                <li>Tipo de navegador.</li>
                <li>Dispositivo.</li>
                <li>Fecha y hora de acceso.</li>
                <li>Pais de origen del acceso.</li>
              </ul>
              <p>Esta recopilación de datos es necesario para un óptimo funcionamiento del sitio web. Estos datos permanecerán almacenados temporalmente durante todo el periodo de uso de la página web para que misma funcione correctamente. Los datos no serán utilizados para fines de marketing. La base legal para el almacenamiento temporal de los datos y los archivos de registro es el artículo 6 párrafo 1 punto f del RGPD.</p>
              <p>Newsletter: Dando su consentimiento puede suscribirse a nuestro boletín de noticias a través del sitio web, mediante el que le informaremos de cualquier novedad con respecto a BC3, OTEA, formaciones, eventos, actualizaciones de los datos, estudios y publicaciones. Almacenaremos la fecha del registro para poder probar su registro y, si es necesario, aclarar un posible uso ilícito de sus datos.</p>

              <p>Podrá revocar su consentimiento a recibir comunicaciones comerciales en cualquier momento, mediante el link o botón al efecto en cada una de las comunicaciones. La información se guarda durante el periodo de tiempo que permanezca suscrito al boletín de noticias.</p>

              <p>Inscripción en formación y eventos: Para asistir a un curso de formación o evento deberá registrarse a través de un formulario en el sitio web. Los datos recogidos serán los siguientes:</p>
              <ul>
                <li>Nombre y apellidos.</li>
                <li>Correo electrónico.</li>
                <li>Institución a la que representa o para la que trabaja.</li>
              </ul>

              <p>Para este tratamiento de los datos, le solicitaremos su consentimiento antes de que se envíe el formulario y le dirigiremos a la política de privacidad mediante un hipervínculo. </p>
              <p>Sus datos serán eliminados tan pronto como dejen de ser necesarios para el fin para el que fueron recopilados, salvo que consienta expresamente suscribirse al boletín de noticias de BC3.</p>

              <h3>Cómo obtenemos tus datos personales</h3>
              <p>Recopilamos información de dos tipos:</p>
              <ul>
                <li>Información que nos proporcionas. Por ejemplo, a través de formularios web te pediremos información personal, como tu nombre o tu dirección de correo electrónico.</li>
                <li>Información de navegación. Se trata de información necesaria para establecer una conexión con nuestros servidores, tal como la dirección IP.</li>
              </ul>
              <h3>Exactitud y veracidad de los datos facilitados</h3>
              <p>Como persona usuaria de los servicios de BC3 nos garantizas y respondes, en cualquier caso, de la exactitud, vigencia y autenticidad de los datos personales que nos facilitas, y te comprometes a mantenerlos debidamente actualizados.</p>
              <p>Así mismo también puedes <a href="https://bc3research.us3.list-manage.com/unsubscribe?u=a35f05878d90b4efcf0c3a9aa&id=72eb637bd0" target="_BLANK">darte de baja</a>.</p>
              <h3>Durante cuento tiempo conservaremos tus datos</h3>
              <p>Los datos personales proporcionados por ti se conservarán mientras sean necesarios para atender a las finalidades para las que, en cada caso, hayan sido recabados al objeto de mantener tu relación o vínculo con BC3 y no solicites su supresión, siendo asimismo conservados conforme a los plazos legales de cumplimiento de obligaciones legales exigibles a BC3.</p>
              <h3>Legitimación. Título legal habilitante para el uso de los datos</h3>
              <p>La legitimación para el tratamiento de los datos por nuestra parte se efectuará en los términos establecidos por el artículo 6 del Reglamento General Europeo de Protección de Datos, relativo a datos ordinarios, o, en su caso, al artículo 9 de dicho Reglamento Europeo relativo a categorías especiales de datos, informándote del título o títulos de licitud que en cada prestación de servicio se apliquen, y que podrán ser el consentimiento, la ejecución de una relación jurídica en la que seas parte, el cumplimiento de una obligación legal o nuestro interés en el desarrollo de los propios servicios y competencias.</p>
              <h3>Destinatarios</h3>
              <p>Podrán tener acceso a tus datos personales nuestros prestadores de servicios, en calidad de encargados de tratamiento, ubicados en la Unión Europea o fuera del Espacio Económico Europeo. bien por necesidad para prestarle el servicio solicitado, bien para poder proporcionarle el servicio con los más altos estándares de calidad. En cualquier caso, BC3 se asegurará que estos tratamientos de datos se protejan siempre con las garantías oportunas:</p>
              <p>Cláusulas tipo aprobadas por la UE: Se trata de contratos aprobados por el regulador Europeo, y que proporcionen las garantías suficientes para garantizar que el tratamiento cumple con los requisitos establecidos por el Reglamento Europeo de Protección de Datos.</p>
              <h3>Certificaciones de terceros.</h3>
              <p>Concretamente, estos prestadores de servicios son:</p>
              <ul>
                <li>Innobask, como proveedor de servicios digitales recogidos en nuestra web.</li>
                <li>Ipartek, como proveedor de servicios de mantenimiento de sistemas.</li>
                <li>Google Analytics, como proveedor de servicios de analítica web, que recoge información de navegación.</li>
                <li>Mailchimp, como proveedor de la plataforma tecnológica que utilizamos para el envío de correos electrónicos del boletín y de la Zona Premium.</li>
              </ul>
              <h3>Cuales son tus derechos</h3>
              <p>Tu, o en su caso tu representante legal tenéis derecho a acceder a tus datos personales objeto de tratamiento, así como solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando los datos ya no sean necesarios para los fines que fueron recogidos, además de ejercer el derecho de oposición y limitación al tratamiento y de portabilidad de los datos. En el caso de haber obtenido tu consentimiento, tienes derecho a revocarlo en cualquier momento.</p>
              <p>Podrás ejercer dichos derechos mediante solicitud escrita acompañada de fotocopia del DNI en la dirección de correo electrónico ARCrights@BC3research.org.</p>
              <p>De este mismo modo podrás retirar el consentimiento en cualquier momento, sin que esto suponga ningún efecto en los tratamientos realizados previamente a dicha retirada. En cualquier caso, podrás presentar la reclamación correspondiente ante la Agencia Española de Protección de Datos.</p>
              <p>Así mismo también puedes <a href="https://bc3research.us3.list-manage.com/unsubscribe?u=a35f05878d90b4efcf0c3a9aa&id=72eb637bd0" target="_BLANK">darte de baja</a>.</p>

              <h3>Otra información</h3>
              <p>Hemos implementado las medidas técnicas necesarias con el fin de proteger tus datos e información de pérdidas accidentales, acceso, uso y divulgación no autorizados. No obstante, pese a la implementación diligente de tales medidas, debes saber que las medidas de seguridad no son inexpugnables. No somos responsable de actuaciones de terceros que, vulnerando dichas medidas, accedan a los mencionados datos e información.</p>

              <h2>4. ACTUALIZACIÓN DE CONDICIONES DE USO Y POLÍTICA DE PRIVACIDAD</h2>
              <p>BC3 se reserva el derecho a actualizar las condiciones de uso y/o la política de privacidad, en caso de ser necesario, se adoptarán las medidas adecuadas para informar a los usuarios de los cambios realizados.</p>

            </div>
          </Col>
        </Row>
      </GeneralContainer>
      
      <GeneralFooter />
    </CustomLayout>
  )
}

const CustomLayout = styled(Layout)`
  background: #fff;
  padding-top: 150px;
`
