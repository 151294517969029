import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Alert } from 'antd';
import { usePostSubscriber } from '../../services/subscriber.service'

export const SubscribeNewsModule: React.FC = () => {
  const [emailValue, setEmailValue] = useState('')
  const [subStatus, setSubStatus] = useState(false)
  const [valMsg, setValMsg] = useState('')
  const postSubscriber = usePostSubscriber()
 
  const checkEmailValue = () => {
    emailValue.includes('@') && emailValue.includes('.') ?
      postSubscriber(emailValue).then(response => {
        response.success ? setSubStatus(true) : setValMsg(`El email introducido ya está suscrito.`)
      }).catch(error => {console.log(error)})
    :
      setValMsg('El email introducido no es válido')
  }

  return (
    <NewsModule>
      <h2>Suscríbete a nuestras noticias</h2>
        {!subStatus ? 
          <div>
            <p>Mantente actualizado con las novedades del observatorio a través de nuestra newsletter</p>
            <input type="mail" 
                  name="EMAIL" 
                  id="mce-EMAIL" 
                  placeholder="Introduce tu email"
                  value={emailValue}
                  onChange={(event) => {setEmailValue(event.target.value)}}
                  required />
            <div className="hiddenfield" aria-hidden="true"><input type="text" name="b_a35f05878d90b4efcf0c3a9aa_72eb637bd0" /></div>
            <button type="submit" id="mc-embedded-subscribe" onClick={() => {checkEmailValue()}}>Suscribirme a la newsletter</button>
            {valMsg !== '' ? 
              <SubsAlertMsg
                message="Error"
                description={valMsg} 
                type="error"
                action={
                  <a href="/"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left-circle"><circle cx="12" cy="12" r="10"></circle><polyline points="12 8 8 12 12 16"></polyline><line x1="16" y1="12" x2="8" y2="12"></line></svg> Volver</a>
                }
                showIcon
              /> : ''}
          </div>
          :
          <div><p>Te has suscrito correctamente a la newsletter de OTEA</p></div>
        } 
        <p><small>Mediante la suscripción, aceptas las condiciones legales contenidas en nuestra <Link to="/politica-privacidad">política de privacidad</Link></small></p>
        <div className='form-icon'></div>
    </NewsModule>
  )
}

const NewsModule = styled.div`
  position: relative;
  background: linear-gradient(118deg, rgba(254,234,207,1) 60%, rgba(210,231,233,1) 100%);
  width: 100%;
  border-radius: 5px;
  padding: 30px;
  margin-top: 40px;
  overflow: hidden;

  @media only screen and (min-width: 1024px) {
    padding: 40px 80px 30px;
  }

  .form-icon {
    background: url(iconos/send-mail.png) no-repeat;
    background-size: 300px;
    position: absolute;
    top: 0px;
    right: 0px;
    opacity: .03;
    height: 300px;
    width: 300px;
    transform: rotate(340deg);
    z-index: 10;
  }

  input {
    border: 1px solid #DDD;
    margin: 20px 0;
    border-radius: 3px;
    padding: 5px;
    width: 100%;
    margin-right: 10px;
    z-index: 100;
    position: relative;

    @media only screen and (min-width: 1024px) {
      width: 50%;
    }
  }

  button {
    padding: 5px 20px;
    margin-bottom: 10px;
    border: 0;
    background: #f9b454;
    border-radius: 3px;
    color: #FFF;
    font-weight: bold;
    width: 100%;
    z-index: 100;
    position: relative;
    
    &:hover {
      cursor: pointer;
    }

    @media only screen and (min-width: 1024px) {
      width: auto;
    }
  }

  .hiddenfield {
    position: absolute; 
    left: -5000px;
  }
`

const SubsAlertMsg = styled(Alert)`
  z-index: 1000;

  a {
    svg {
      position: relative;
      top: 1px;
    }
  }
`
