import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFetch } from 'use-http'
import { getAbsoluteServerUrl } from '../config/config'
import { Row, Layout } from 'antd'
import styled from 'styled-components'
import { MainMenu } from '../layouts/components/MainMenu'
import { SmallVisor } from '../layouts/components/SmallVisor'
import { GeneralContainer } from '../layouts/components/GeneralContainer'
import { GeneralFooter } from '../layouts/components/GeneralFooter'
import { BlogPost } from '../layouts/components/BlogPost'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

type BlogArticle = { 
  id: number; 
  title: string;
  description: string;
  url: string; 
  body: string;
  published: boolean
  created_at: Date 
  updated_at: Date 
}

export const PostPage: React.FC = () => {
  const { url } = useParams<{ url: string }>()
  const  articleRequest  = useFetch<BlogArticle>(getAbsoluteServerUrl(`/articles/${url}`), { responseType: 'json' }, [])

  const article = articleRequest.data
  
  return (
    <CustomLayout>
      <Helmet>
        <title>{article?.title}</title>
        <meta name="description" content={article?.description} />
        <meta name="keywords" content="Artículos, noticias, estudios, publicaciones, blog, post, transición energética, energía, sociedad, economía, objetivos energéticos, acción climática" />
        <meta name="author" content="OTEA: Observatorio de la transición energética y la acción climática" />
      </Helmet>
      <MainMenu />
      <SmallVisor pageTitle="Artículos OTEA" />
      <GeneralContainer>
        <Row>{article?
            <BlogPost
              postId={article.id}
              postDescription={article.description}
              postBody={article.body}
              postDate={article.updated_at}
              postTitle={article.title}
              postUrl={article.url} />
              :
              ''
            }
        </Row>
        <BackLink to={'/noticias'}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 17L6 12M6 12L11 7M6 12L18 12" stroke="#30cdde" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg> Volver al listado de noticias</BackLink>
      </GeneralContainer>
      <GeneralFooter />
    </CustomLayout>
  )
}
const CustomLayout = styled(Layout)`
  background: #fff;
`

const BackLink = styled(Link)`
  svg {
    position: relative;
    top: 6px;
  }
`
