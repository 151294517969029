import React from 'react'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { GeneralContainer } from '../layouts/components/GeneralContainer'
import { useGetCategories } from '../services/categories.service'
import { useGetSubcategories } from '../services/subcategories.service'
import { useGetIndicators } from '../services/indicators.service'
import { GeneralFooter } from '../layouts/components/GeneralFooter'
import { DBSearch } from '../layouts/components/DBSearch'

const { Content } = Layout
const { SubMenu } = Menu

type Props = {
  currentPage?: string
  title: string
}

export const DefaultLayout: React.FC<Props> = ({ currentPage, children }) => {
  const categoriesRequest = useGetCategories()
  const categories = categoriesRequest.data || []

  const subcategoriesRequest = useGetSubcategories()
  const subcategories = subcategoriesRequest.data || []

  const indicatorsRequest = useGetIndicators()
  const indicators = indicatorsRequest.data || []

  return (
    <BaseLayout>
      {indicators.length > 0 ? (
        <GeneralContainer>
          <DBSearch />
          
          <Menu defaultSelectedKeys={currentPage ? [currentPage] : undefined} mode="horizontal">
            {categories.map(category => (
              <DBMenu
                key={category.id}
                icon={
                  <img
                    src={category.attachment && category.attachment.length ? category.attachment[0].url : undefined}
                  />
                }
                title={category.name}>
                {subcategories
                  .filter(subcategory => subcategory.category === category.id)
                  .map(subcategory => (
                    <Menu.ItemGroup key={subcategory.id} title={subcategory.name}>
                      {indicators
                        .filter(indicator => indicator.subcategory === subcategory.id)
                        .map(indicator => (
                          <Menu.Item key={indicator.id}>
                            <Link to={`/bdd/${indicator.code.substring(0, 2)}/${indicator.code}`}>
                              {indicator.title}
                            </Link>
                          </Menu.Item>
                        ))}
                    </Menu.ItemGroup>
                  ))}
              </DBMenu>
            ))}
          </Menu>
        </GeneralContainer>
      ) : (
        ''
      )}
      <Layout>
        <Content style={{ backgroundColor: 'white' }}>{children}</Content>
      </Layout>
      <GeneralFooter />
    </BaseLayout>
  )
}

const BaseLayout = styled(Layout)`
  min-height: 100vh;
  background-color: white;
`

const DBMenu = styled(SubMenu)`
  img {
    width: 16px;
    margin-right: 10px;
    position: relative;
    top: -2px;
  }
`
