import React from 'react'
import { useParams } from 'react-router-dom'
import { Layout } from 'antd'
import useFetch from 'use-http'
import styled from 'styled-components'
import { GeneralContainer } from '../layouts/components/GeneralContainer'
import { DefaultLayout } from '../layouts/DefaultLayout'
import { ChartData, transformRawIndicatorData, useGetIndicators } from '../config/indicators-model'
import { MainMenu } from '../layouts/components/MainMenu'
import { DBVisor } from '../layouts/components/DBVisor'
import { Chart } from '../components/Chart'
import { getAbsoluteServerUrl } from '../config/config'

export const Indicators: React.FC = () => {
  const { chart, subChart } = useParams<{ chart: string; subChart: string }>()

  const fetchUrl = getAbsoluteServerUrl(`/indicators/${subChart}`)
  const dataRequest = useFetch<ChartData>(fetchUrl, { responseType: 'json' }, [fetchUrl])
  const chartData = dataRequest.data ? transformRawIndicatorData(dataRequest.data) : undefined

  const indicatorsRequest = useGetIndicators()
  const indicators = indicatorsRequest.data || []

  if (!indicators.length) {
    return <></>
  }

  if (!chartData || !chartData[subChart]) {
    return <></>
  }

  const selectedChart = indicators.find(c => c.code === chart)

  if (!selectedChart) {
    throw Error('Page not found')
  }

  const selectedSubChart = selectedChart.indicators.find(sc => sc.code === subChart)

  if (!selectedSubChart) {
    throw Error('Page not found')
  }

  return (
    <CustomLayout>
      <MainMenu />
      <DBVisor pageTitle="Base de datos" />
      <DefaultLayout
        currentPage={`/bdd/${chart}/${subChart}`}
        title={`${selectedChart.title} - ${selectedSubChart.title}`}>
        <GeneralContainer>
          <Chart chart={chart} subChart={subChart} />
        </GeneralContainer>
      </DefaultLayout>
    </CustomLayout>
  )
}

const CustomLayout = styled(Layout)`
  padding-top: 0;
  background-color: #fff;

  @media only screen and (min-width: 1024px) {
    padding-top: 50px;
  }
`
