import React from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const MainMenu: React.FC = () => {
  const currentPage = window.location.pathname

  return (
    <TopMenu>
      <Row className="mainMenu">
        <Col xs={24} lg={{ span: 22, offset: 1 }} xl={{ span: 20, offset: 2 }} xxl={{ span: 14, offset: 5 }}>
          <Link to="/"><img
            src="/logos/logo-menu.png"
            alt="OTEA: Observatorio para la Transición Energética y la Acción Climática"
          /></Link>
          <ul>
            <li>
              <Link to="/" className={`item${currentPage === '/' ? ' active' : ''}`}>
                Inicio
              </Link>
            </li>
            <li>
              <Link className={`item${currentPage.indexOf('/observatorio') === 0 ? ' active' : ''}`} to="/observatorio">
                El observatorio
              </Link>
            </li>
            <li>
              <Link className={`item${currentPage.indexOf('/accionclima') === 0 ? ' active' : ''}`} to="/accionclima">
                Acción climática
              </Link>
            </li>
            <li>
              <Link className={`item${currentPage.indexOf('/bdd') === 0 ? ' active' : ''}`} id="bdd" to="/bdd/C3/C31">
                Base de datos
              </Link>
            </li>
            <li>
              <Link className={`item${currentPage.indexOf('/transicion') === 0 ? ' active' : ''}`} to="/transicion">
                De un vistazo
              </Link>
            </li>
            <li>
              <Link className={`item${currentPage.indexOf('/recursos') === 0 ? ' active' : ''}`} to="/recursos">
                Recursos
              </Link>
            </li>
            <li>
              <Link className={`item${currentPage.indexOf('/noticias') === 0 ? ' active' : ''}`} to="/noticias">
                Noticias
              </Link>
            </li>
          </ul>
        </Col>
      </Row>

      <nav>
        <input type="checkbox" id="menuToggle" />
        <label htmlFor="menuToggle">
          <span>&times;</span>
          <span>&#9776;</span>
        </label>
        <ul className="mobileMenu">
          <li>
            <Link to="/" className={`item${currentPage.indexOf('/home') === 0 ? ' active' : ''}`}>
              Inicio
            </Link>
          </li>
          <li>
            <Link className={`item${currentPage.indexOf('/observatorio') === 0 ? ' active' : ''}`} to="/observatorio">
              El observatorio
            </Link>
          </li>
          <li>
            <Link className={`item${currentPage.indexOf('/accionclima') === 0 ? ' active' : ''}`} to="/accionclima">
              Acción climática
            </Link>
          </li>
          <li>
            <Link className={`item${currentPage.indexOf('/bdd') === 0 ? ' active' : ''}`} id="bdd" to="/bdd/C3/C31">
              Base de datos
            </Link>
          </li>
          <li>
            <Link className={`item${currentPage.indexOf('/transicion') === 0 ? ' active' : ''}`} to="/transicion">
              De un vistazo
            </Link>
          </li>
          <li>
            <Link className={`item${currentPage.indexOf('/recursos') === 0 ? ' active' : ''}`} to="/recursos">
              Recursos
            </Link>
          </li>
          <li>
            <Link className={`item${currentPage.indexOf('/noticias') === 0 ? ' active' : ''}`} to="/noticias">
              Noticias
            </Link>
          </li>
        </ul>
      </nav>
    </TopMenu>
  )
}

const TopMenu = styled.div`
  .mainMenu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 80px;
    width: 100%;
    font-size: 0.7em;
    background: #fff;
    background: #efefef;
    display: none;
    font-weight: bold;

    @media only screen and (min-width: 1024px) {
      display: block;
    }

    ul {
      margin: 0;

      li {
        display: inline;
        float: left;
        
        a {
          display: block;
          text-transform: uppercase;
          height: 80px;
          line-height: 80px;
          padding: 0 15px;
          color: #222;
          transform: color 0.3s linear;
      
          &:hover {
            color: #30cdde;
          }
      
          &.active {
            background: #30cdde;
            border-radius: 5px 5px 0 0;
            margin-top: 20px;
            height: 60px;
            line-height: 40px;
            color: #fff;
          }
        }
      }
    }

    img {
      width: 100px;
      float: left;
      position: relative;
      top: 17px;
      left: 10px;
      margin-right: 50px;
    }
  }

  nav {
    position: fixed;
    top: 20px;
    right: 20px;
    min-width: 40px;
    min-height: 40px;
    overflow: hidden;
    z-index: 1000;
    font-size: 1.5em;

    @media only screen and (min-width: 1024px) {
      display: none;
    }

    label {
      display: block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #000;
      text-align: center;
      line-height: 50px;
      color: #fff;
    }

    .mobileMenu {
      overflow: hidden;
      width: 0;
      height: 0;
      padding: 0;
      margin: 0;
      transition: width 0.5s, height 0.5s;
      list-style: none;

      li {
        a {
          background: rgba(0, 0, 0, 0.8);
          color: #fff;
          height: 35px;
          line-height: 35px;
          border-radius: 20px;
          margin-top: 10px;
          font-size: 16px;
          padding: 0 10px;
          display: block;
        }
      }
    }

    input {
      display: none;

      &:checked ~ label span:last-child {
        display: none;
      }

      &:not(:checked) ~ label span:first-child {
        display: none;
      }

      &:checked ~ ul {
        width: 280px;
        height: 320px;
      }
    }
  }
`
