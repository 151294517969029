import React from 'react'
import {Layout} from 'antd'
import styled from 'styled-components'
import {MainMenu} from '../layouts/components/MainMenu'
import {SmallVisor} from '../layouts/components/SmallVisor'
import {GeneralContainer} from '../layouts/components/GeneralContainer'
import {GeneralFooter} from '../layouts/components/GeneralFooter'
import {Chart} from '../components/Chart'
import {ChartTypesEnum} from "../config/indicators-model";
import { Helmet } from "react-helmet";

export const Transition: React.FC = () => {
  return (
    <CustomLayout>
      <Helmet>
        <title>La transición energética de un vistazo</title>
        <meta name="description" content="La transición energética de un vistazo" />
        <meta name="keywords" content="Cambio climático, transición ecológica, artículos, noticias, estudios, publicaciones, transición energética, energía, sociedad, economía, objetivos energéticos, acción climática" />
        <meta name="author" content="OTEA: Observatorio de la transición energética y la acción climática" />
      </Helmet>
      <MainMenu />
      <SmallVisor pageTitle="La transición energética de un vistazo" />
      <GeneralContainer>
        <h2>Descarbonización</h2>
        <h3>Emisiones totales</h3>
        <p>
        Según nuestras estimaciones, las emisiones de gases de efecto invernadero (GEI) en 2022 serían 297
        MtCO2 eq, lo que supone un aumento del 3% con respecto a las de 2011 (289 MtCO2eq) y por
        encima de las de 1990 (290 MtCO2eq). El objetivo de reducción establecido en el Plan Nacional
        Integrado de Energía y Clima (PNIEC) implica reducir las emisiones totales de GEI un 23% en 2030 y
        un 90% en 2050 con respecto a 1990. O lo que es lo mismo, reducir las emisiones hasta alcanzar las
        222 MtCO2eq en el horizonte 2030 y las 29 MtCO2eq en 2050.
        </p>
          <Chart chart="C3" subChart="C31" />
        <h3>Descomposición Emisiones de CO2</h3>
        <p>
        Un análisis de descomposición por factores permite analizar cuáles han sido las claves en la variación
        de las emisiones en los últimos años. El aumento de la población habría contribuido a aumentar las
        emisiones entre 1990 y 2022 en 77 MtCO2eq y el aumento del PIB per cápita en 99 MtCO2eq. Por
        contra, la mejora de la intensidad energética primaria habría contribuido a reducirlas en 70
        MtCO2eq, la reducción en la relación entre emisiones energéticas por energía consumida habría
        contribuido a reducirlas en 78 MtCO2 eq y la mejora en la intensidad de emisiones no energéticas en
        44 MtCO2eq en este periodo.
        </p>
          <Chart chart="C7" subChart="C71" defaultChartType={ChartTypesEnum.Waterfall} defaultYear={2020} />
        <h2>Energía</h2>
        <h3>Consumo de energía primaria por fuente</h3>
        <p>
        El consumo de energía primaria estimado en España para el año 2022 en 119.980 ktep (incluyendo
        usos no energéticos). El objetivo establecido en el PNIEC supone una reducción enel consumo
        primario hasta alcanzar los 104.099 ktep en 2030 (o los 98,5 Mtep sin incluir los usos no
        energéticos). Esta reducción supone una mejora de la eficiencia energética del 39,5% respecto a las
        proyecciones a 2030 del Modelo PRIMES (2007) de la Comisión Europea, que sirve de referencia en
        la Directiva de Eficiencia Energética para fijar el objetivo orientativo de consumo de energía primaria
        de la Unión Europea en 2030.
        </p>
        <p>
          <Chart chart="E1" subChart="E11" defaultChartType={ChartTypesEnum.Area} />
        </p>
        <h3>Producción de energía eléctrica por tecnologías</h3>
        <p>
        La producción de energía eléctrica mediante fuentes renovables representó en España en 2022 el
        42% del total de la generación. Se espera que en 2030 las renovables alcancen el 74% en el mix
        eléctrico y el 100% en 2050. A su vez se espera que la generación mediante ciclos combinados y
        centrales térmicas de carbón, que representaron en 2022 el 27% de la generación eléctrica,
        reduzcan progresivamente su participación en el mix eléctrico. Por otro lado, las centrales nucleares,
        que fueron responsables de un 20% de la generación eléctrica en 2022, están en vías de reducir su
        actividad al cumplir con su vida útil entre 2027 y 2035. Otras tecnologías como la cogeneración
        habrían supuesto el 6% de la generación eléctrica restante en 2022.
        </p>
          <Chart chart="E3" subChart="E32" defaultChartType={ChartTypesEnum.ColumnStacked} />
        <h2>Renovables</h2>
        <h3>Renovables sobre consumo final en porcentaje</h3>
        <p>
        La mayor utilización de energía procedente de fuentes renovables constituye una parte importante
        de las medidas necesarias para reducir las emisiones de GEI. Además, las energías renovables,
        contribuyen por su carácter autóctono a la reducción de las tasas de dependencia energética. Es por
        ello por lo que la Unión Europea estableció para 2020 un objetivo mínimo del 20% de energías
        renovables sobre el consumo de energía final y del 32,5% para 2030. España alcanzó en 2020 un
        21,2% de renovables sobre el consumo de energía final (RES) lo que supondría haber superado el
        objetivo establecido por la UE. En 2022 las renovables representaron un 22% y se espera que éstas
        aumenten su participación hasta el 42% en 2030.
        </p>
          <Chart chart="E4" subChart="E45" disableSeries={['E451','E452','E453']} />
        <h3>Cuota de energía renovable por usos</h3>
        <p>
        Las renovables habrían supuesto en 2022 un 20% de la energía final consumida para calefacción y
        refrigeración (RES-H&amp;C) y un 7,6% de la energía final consumida en el transporte (RES-T). La
        incorporación de energías renovables en el consumo final de energía se ha producido
        principalmente por la incorporación de renovables en la generación eléctrica. No obstante, con el fin
        de incorporar renovables también en otros sectores existen obligaciones. Según el PNIEC se espera
        un 28% de renovables en el sector transporte en 2030 vía electrificación y biocombustibles.
        </p>
          <Chart chart="E4" subChart="E45" disableSeries={['E454']} />
        <h2>Electrificación</h2>
        <h3>Consumo de electricidad por sectores</h3>
        <p>
        La electrificación de la economía es fundamental para alcanzar los objetivos de descarbonización
        establecidos, puesto que la electricidad tiene ciertas ventajas frente a otras fuentes energéticas, ya
        que puede ser generada mediante renovables, y, además, permite la sustitución de tecnologías
        fósiles por otras que utilizan la energía eléctrica que son más eficientes. En 2022 el grado de
        electrificación de la economía se situó en el 24,5%. El sector con mayor consumo de electricidad
        sobre el total de energía consumida habría sido el sector Comercial (con un 59%), aunque se espera
        un aumento progresivo en el grado de electrificación de todos los sectores de la economía.
        </p>
        <Chart chart="E5" subChart="E51" />
        <h3>Stock de vehículos eléctricos</h3>
        <p>
        El sector del transporte contribuye en más de una cuarta parte al total de emisiones de GEI de la
        economía española y depende en su práctica totalidad de combustibles fósiles. La electrificación del
        transporte será clave para alcanzar los objetivos establecidos en el PNIEC. El actual parque de
        vehículos eléctricos se sitúa aproximadamente en 200.000 unidades y en 2022 se matricularon unos
        50.000 nuevos vehículos (lo que supone un aumento de las matriculaciones en un 29% en
        comparación con el año anterior). Se espera que en el año 2030 existan en el parque automovilístico
        unos 3 millones de turismos eléctricos y más de dos millones de motocicletas, camiones ligeros y
        autobuses, es decir, un total de 5 millones de unidades.
        </p>
        <Chart chart="E7" subChart="E72" defaultChartType={ChartTypesEnum.Area} />
        <h2>Economía y sociedad</h2>
        <h3>Dependencia energética (%)</h3>
        <p>
          Los hidrocarburos son la fuente predominante en el mix de energía primaria nacional. Por este
          motivo, la dependencia energética exterior de España continúa siendo muy elevada. Se estima que
          la dependencia energética en España en 2022 fue del 74%. Se espera que las actuaciones en materia
          de renovables y eficiencia energética disminuyan el grado de dependencia hasta un 61% en 2030, lo
          que además de mejorar la seguridad energética nacional tendrá un impacto muy favorable sobre la
          balanza comercial.
        </p>
        <Chart chart="D5" subChart="D51" />
        <h3>Pobreza energética y desigualdad</h3>
        <p>
          La pobreza energética se define como la situación en la que se encuentra un hogar cuyas
          necesidades básicas de suministros de energía no pueden ser satisfechas. Los altos precios
          energéticos de 2021 llevaron a que la mayor parte de los indicadores de pobreza energética
          aumentasen. En 2022 se espera una evolución similar. Continúa siendo preocupante el impacto de
          esta problemática sobre hogares cuya persona de referencia en el hogar son mujeres, como muestra
          el índice 2M de género. Esta situación puede responder a la coexistencia de bajos ingresos con
          equipamientos y aislamientos deficientes que hace que estos hogares gasten una mayor proporción
          de sus ingresos en energía.
        </p>
        <Chart chart="S4" subChart="S42" />
      </GeneralContainer>
      <GeneralFooter />
    </CustomLayout>
  )
}

const CustomLayout = styled(Layout)`
  background: #fff;
`
