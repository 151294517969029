import { Url } from 'url'
import { useFetch } from 'use-http'
import { getAbsoluteServerUrl } from '../config/config'

type Indicator = { 
  id: number; 
  subcategory: number; 
  title: string; 
  long_title: string;
  code: string;
  export_file_name: string;
  source: string;
  source_link: string;
  source2: string;
  source2_link: string;
  derivation: boolean;
  periodicity: string;
  available_range_from: number;
  available_range_to: number;
  estimated_delay: string;
  description: string;
  unit: string;
  unit2: string;
  unit3: string;
  multiplier_1to2: number;
  multiplier_1to3: number;
  start_from_zero: boolean;
  chart_area: boolean;
  chart_percent: boolean;
  chart_line: boolean;
  chart_pie: boolean;
  chart_bar: boolean;
  chart_column: boolean;
  chart_column_stacked: boolean;
  chart_waterfall: boolean;
  chart_map: boolean;
  remarks: string;
  internal_comments: string;
  spatial_disaggregation: string;
  temporal_dissaggregation: string;
  eu_comparison: string;
  default_chart: string;
  default_category: string;
  created_at: Date; 
  updated_at: Date; 
}

export function useGetIndicators() {
  return useFetch<Indicator[]>(getAbsoluteServerUrl('/indicators'), { responseType: 'json' }, [])
}
